<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableList'
import { downloadAction, getAction, getParams } from '@/command/netTool'
import Big from 'big.js'

export default {
  name: 'transaction',
  data() {
    return {
      records: [],
      totalData: null,
      current: 1,
      total: 1,
      // listUrl: '/reportForm/getMerchantReportPage',
      params: { current: 1, pageSize: 10 },
      paramsValue: {},
      businessIdList: [],
      headerTypeData: {
        fromType: [
          {
            name: '农家特产',
            value: '1',
          },
          {
            name: '农家乐',
            value: '2',
          },
          {
            name: '农事体验',
            value: '4',
          },
          {
            name: '酒店民宿',
            value: '13',
          },
          {
            name: '景点门票',
            value: '12',
          },
          {
            name: '组合套餐',
            value: '14',
          },
        ],
        businessId: [],
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getTotal(params) {
      return new Promise((resolve) => {
        // if (this.totalData) {
        //   resolve(this.totalData)
        // } else {
        this.params = {
          ...this.params,
          ...params,
        }
        getAction('/reportForm/getBusinessReportFormTotal', params).then((e) => {
          this.totalData = e.data
          resolve(e.data)
        })
        // }
      })
    },
    getSum(list) {
      let orderCount = 0
      let totalAmount = 0
      let productQuantityCompleted = 0
      let totalAmountCompleted = 0
      let refundAmount = 0
      let salesJsAmount = 0
      let sjRzAmount = 0
      let refundCount = 0
      let totalTransactions = 0
      let refundTransactions = 0
      list.forEach((e) => {
        orderCount = parseFloat(new Big(e.orderCount || 0).plus(orderCount))
        totalAmount = parseFloat(new Big(e.totalAmount || 0).plus(totalAmount))
        productQuantityCompleted = parseFloat(new Big(e.productQuantityCompleted || 0).plus(productQuantityCompleted))
        refundAmount = parseFloat(new Big(e.refundAmount || 0).plus(refundAmount))
        totalAmountCompleted = parseFloat(new Big(e.totalAmountCompleted || 0).plus(totalAmountCompleted))
        salesJsAmount = parseFloat(new Big(e.salesJsAmount || 0).plus(salesJsAmount))
        sjRzAmount = parseFloat(new Big(e.sjRzAmount || 0).plus(sjRzAmount))
        refundCount = parseFloat(new Big(e.refundCount || 0).plus(refundCount))
        totalTransactions = parseFloat(new Big(e.totalTransactions || 0).plus(totalTransactions))
        refundTransactions = parseFloat(new Big(e.refundTransactions || 0).plus(refundTransactions))
      })
      return {
        productName: '合计',
        orderCount,
        totalAmount,
        productQuantityCompleted,
        totalAmountCompleted,
        refundAmount,
        salesJsAmount,
        sjRzAmount,
        refundCount,
        totalTransactions,
        refundTransactions,
      }
    },
    getList(params = this.params) {
      this.getTotal(params).then((total) => {
        getAction('/reportForm/getBusinessReportFormPage', params).then((e) => {
          this.records = e.data['records'].map((e) => ({ ...e, dateTypeQuery: params.dateTypeQuery }))
          this.params = params
          this.params['current'] = e.data['current']
          this.params['pageSize'] = e.data['pageSize']
          this.current = e.data['current']
          this.total = e.data['total']
          if (this.records.length !== 0) {
            this.records.push(this.getSum(e.data['records']))
            this.records.push({
              ...total,
              orderCount: total.orderCount,
              totalAmount: total.totalAmount,
              productQuantityCompleted: total.productQuantityCompleted,
              totalAmountCompleted: total.totalAmountCompleted,
              refundCount: total.refundCount,
              refundAmount: total.refundAmount,
              salesJsAmount: total.salesJsAmount,
              sjRzAmount: total.sjRzAmount,
              totalTransactions: total.totalTransactions,
              refundTransactions: total.refundTransactions,
              productName: '总计',
            })
          }
        })
      })
    },
    getHeader() {
      return [
        {
          type: 'select',
          name: '产品类型',
          key: 'fromType',
        },
        {
          name: '商品名称',
          type: 'input',
          key: 'productName',
        },
        {
          name: '日期',
          type: 'rangePicker',
          keys: ['dateGe', 'dateLe'],
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '商品名称',
          dataIndex: 'productName',
        },
        {
          title: '商品类型',
          dataIndex: 'fromType',
          customRender: (text) => {
            const obj = this.headerTypeData.fromType.find((d) => d.value == text)
            return obj.name
          },
        },
        {
          title: '下单数量',
          dataIndex: 'orderCount',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.orderCount - b.orderCount
            }
          },
        },
        {
          title: '交易量',
          dataIndex: 'totalTransactions',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.totalTransactions - b.totalTransactions
            }
          },
        },
        {
          title: '退款数',
          dataIndex: 'refundTransactions',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.refundTransactions - b.refundTransactions
            }
          },
        },
        {
          title: '下单金额',
          dataIndex: 'totalAmount',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.totalAmount - b.totalAmount
            }
          },
        },
        {
          title: '核销数量',
          dataIndex: 'productQuantityCompleted',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.productQuantityCompleted - b.productQuantityCompleted
            }
          },
        },
        {
          title: '核销金额',
          dataIndex: 'totalAmountCompleted',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.totalAmountCompleted - b.totalAmountCompleted
            }
          },
        },
        {
          title: '退款数量',
          dataIndex: 'refundCount',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.refundCount - b.refundCount
            }
          },
        },
        {
          title: '退款金额',
          dataIndex: 'refundAmount',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.refundAmount - b.refundAmount
            }
          },
          customRender: (text) => {
            return text || '0'
          },
        },
        {
          title: '结算金额',
          dataIndex: 'salesJsAmount',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.salesJsAmount - b.salesJsAmount
            }
          },
        },
        {
          title: '实际入账金额',
          dataIndex: 'sjRzAmount',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.sjRzAmount - b.sjRzAmount
            }
          },
        },
      ]
    },
    onChange(data, filter) {
      this.getList(data)
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            const { dateGe, dateLe, fromType, productName } = this.params
            let urlParam = `fromType=${fromType || ''}`
            if (productName) {
              urlParam = urlParam + `&productName=${productName}`
            }
            if (dateGe) {
              urlParam = urlParam + `&dateGe=${dateGe}&dateLe=${dateLe}`
            }

            if (this.businessIdList.length > 0) {
              urlParam = urlParam + `&ids=${this.businessIdList}`
            }

            downloadAction(`/api/reportForm/exportBusinessReportForm?${urlParam}`, '业务报表.xlsx')
          },
        },
      ]
    },
    selectRow(data, records) {
      this.businessIdList = records.map((d) => d.id)
    },
  },
  render() {
    return (
      <TemplateHeaderTableNew
        ref={'table'}
        records={this.records}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        rightButton={this.getRightButton()}
        button={[]}
        filterRightButtonKey={['refresh', 'setting']}
        onChange={this.onChange}
        headerTypeData={this.headerTypeData}
        onSelectRow={this.selectRow}
      />
    )
  },
}
</script>
